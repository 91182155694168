import Plausible, { type EventOptions, type PlausibleOptions } from "plausible-tracker";

const plausible = Plausible({
	domain: "fensterplatz-online.ch",
});

export function initializePlausible() {
	plausible.trackPageview();
	plausible.enableAutoOutboundTracking();
}

export function trackEvent(eventName: string, options?: EventOptions, eventData?: PlausibleOptions) {
	try {
		plausible.trackEvent(eventName, options, eventData);
	} catch {}
}
