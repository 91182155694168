import "../Styles/index.scss";

import { Fancybox } from "@fancyapps/ui";
import "waypoints/lib/noframework.waypoints.js";
import Flickity from "flickity";
import { initializePlausible } from "./analytics";
import svelteRetag from "svelte-retag";

const ScrolledClass = "scrolled";
const ActiveClass = "is-active";

const nav = document.querySelector("nav");
const logo = document.querySelector(".logo");

Fancybox.bind(".is-zoomable");

let homeFlickityElement = document.getElementById("homeFlickity");
if (homeFlickityElement != null) {
	new Flickity(homeFlickityElement, {
		autoPlay: 4000,
		wrapAround: true,
		pageDots: false,
		prevNextButtons: false,
	});
}

let scrollToContentButton = document.getElementById("contentButton");
if (scrollToContentButton != null) {
	var waypoint = new Waypoint({
		element: scrollToContentButton,
		handler: direction => {
			if (direction === "down") {
				nav?.classList.add(ScrolledClass);
				logo?.classList.add(ScrolledClass);
			}
			else {
				nav?.classList.remove(ScrolledClass);
				logo?.classList.remove(ScrolledClass);
			}
		}
	});
}

// button click scroll
for (let button of document.querySelectorAll(".button")) {
	button.addEventListener("click", e => {
		let target = e.target as HTMLElement;
		let scrollElement: HTMLElement | null = null;

		if (target.id === "galleryButton") {
			scrollElement = document.querySelector(".image");
		} else if (target.id === "contentButton") {
			scrollElement = document.querySelector("main");
		} else if (target.id === "topButton") {
			scrollElement = document.querySelector("body");
		}

		if (scrollElement != null) {
			window.scrollTo({ behavior: "smooth", top: scrollElement.offsetTop });
		}
	});
}

// touch click first level nav
for (let anchor of document.querySelectorAll("nav > ul > li.has-children > a")) {
	anchor.addEventListener("click", e => e.preventDefault());
}

// mobile navigation
document.querySelector("#mobileMenuButton")?.addEventListener("click", () => {
	nav?.classList.toggle("open");
});

if (document.querySelector("enter-contest")) {
	import("./enter-contest.svelte").then(m => svelteRetag({
		component: m.default,
		tagname: "enter-contest"
	}));
}

if (document.querySelector("contact-form")) {
	import("./contact-form.svelte").then(m => svelteRetag({
		component: m.default,
		tagname: "contact-form"
	}));
}

initializePlausible();
